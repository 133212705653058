import React from 'react';

const ZionContent = ({ publication, renderComponent }) => {
  if (!publication) {
    return null;
  }

  return publication.children.map(child => <div key={child._id}>{renderComponent(child)}</div>);
};

export default ZionContent;
